.sub-header {
  text-align: left;
  font-size: 20px;
  padding-top: 25px;
}

.sort-button {
  display: inline-block;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #3d3d3d;
  color: black;
  text-align: center;
  padding: 10px;
  width: 350px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.sort-button:hover {
  font-weight: bold;
  background-color: #3d3d3d;
  color: #f5f5f5;
}

table.table.table-dark.table-striped.table-bordered.table-hover {
  table-layout: auto;
  /* width:1px;
  white-space:nowrap; */
}

.table-header,
.table-content {
  text-align: center;
}

table {
  border-collapse: collapse;
  background: hsla(0, 0%, 100%, 0.4);
  border: 1px solid black;
}

table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  border-spacing: 2px;
  border-color: grey;
  z-index: -1;
}

th {
  font-weight: 700;
  white-space: nowrap;
  background: #d1d1d1;
  color: #000000;
}

tr,
th,
td {
  border: 1px solid #000000;
  padding: 0.75rem;
}

tr.border td,
tr.border th {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-top: 1px solid black;
}

.table-button {
  display: inline-block;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #3d3d3d;
  color: black;
  text-align: center;
  padding: 10px;
  width: 100px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.table-button:hover {
  background-color: #3d3d3d;
  color: #f5f5f5;
}
