@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* font style menu */

/* font style header */

/* font style content */

/* font style button */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Work Sans", sans-serif;
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 50px;
  text-align: center;
  width: 100%;
}

.header {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  margin-bottom: 50px;
  text-align: center;
  width: 100%;
}

.show-all-vehicles,
.update-a-vehicle,
.register-new-vec,
.upload-vec-hist,
.show-vehicle-history,
.logbook-vehicle {
  /* display: block; */
  /* height: 200vh; */
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-left: 50px;
  margin-top: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
}

.header2 {
  margin-bottom: 25px;
  text-align: center;
}

.logo {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
}

img {
  max-width: 150px;
}

.card {
  width: 500px;
  padding: 50px;
}

.login {
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-left: 40px;
  margin-top: 100px;
  margin-right: 100px;
  margin-bottom: 150px;
  text-align: center;
}

.err-msg {
  font-weight: bold;
  color: red;
}

.success-msg {
  font-weight: bold;
  color: #014775;
  text-align: center;
  margin-top: 75px;
  margin-bottom: 75px;
}

.required {
  font-weight: bold;
  color: red;
  vertical-align: super;
}

.form-label {
  font-weight: bold;
}

.form-content {
  font-weight: normal;
}

.note {
  font-size: 14px;
}

.button-default,
button.btn.btn-primary {
  display: inline-block;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #3d3d3d;
  color: black;
  text-align: center;
  padding: 10px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
}

.button-default:hover,
button.btn.btn-primary:hover {
  font-weight: bold;
  background-color: #3d3d3d;
  color: #f5f5f5;
}

/* .dropdown-toggle.btn.btn-primary { */
.dropdown {
  width: 400px;
  border-radius: 0px;
  border: 20x solid grey;
  text-align: left;
}

.form-select {
  z-index: -1;
}

.footer {
  border-top: 1px solid #d1d1d1;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: auto;
}

.footer-left {
  /* position: absolute; */
  text-align: center;
  float: left;
  width: 20%;
}

.footer-right {
  /* position: absolute; */
  text-align: center;
  float: right;
  width: 20%;
}

:link,
a {
  color: black;
  text-decoration: none;
}

:link:hover,
a:hover {
  color: #474747;
}

:link:active,
a:active {
  color: #474747;
}

@media only screen and (min-width: 769px) {
}

@media only screen and (max-width: 768px) {
  .footer {
    border-top: 1px solid #d1d1d1;
    text-align: center;
    font-size: 12px;
  }

  .footer-left {
    text-align: left;
    width: 50%;
    padding-bottom: 20px;
  }

  .footer-right {
    text-align: right;
    width: 50%;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 480px) {
  img {
    max-width: 100px;
  }

  .card {
    width: 400px;
    padding: 20px;
  }

  .show-all-vehicles,
  .update-a-vehicle,
  .register-new-vec,
  .upload-vec-hist,
  .show-vehicle-history,
  .logbook-vehicle {
    /* display: block; */
    /* height: 200vh; */
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-left: 20px;
    margin-top: 50px;
    margin-right: 20px;
    margin-bottom: 50px;
  }

  .footer {
    border-top: 1px solid #d1d1d1;
    text-align: center;
    font-size: 12px;
  }

  .footer-left {
    text-align: center;
    width: 100%;
    padding-bottom: 20px;
  }

  .footer-right {
    text-align: center;
    width: 100%;
    padding-bottom: 20px;
  }
}

.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #ffffff;
}

.box {
  position: relative;
  width: 300px;
  height: 400px;
  background: #000000;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.box::after {
  content: "";
  position: absolute;
  width: 500px;
  height: 500px;
  background-image: conic-gradient(
    transparent,
    transparent,
    transparent,
    #0067c7
  );
  animation: animate 4s linear infinite;
  animation-delay: -2s;
}

.box::before {
  content: "";
  position: absolute;
  width: 500px;
  height: 500px;
  background-image: conic-gradient(
    transparent,
    transparent,
    transparent,
    #f7882f
  );
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.box span {
  position: absolute;
  inset: 5px;
  border-radius: 16px;
  background: #ffffff;
  z-index: 1;
}

.loading-image {
  position: absolute;
  justify-content: center;
  align-items: center;
}

.box h2 {
  position: absolute;
  z-index: 2;
  color: #ffffff;
  font-size: 20px;
}

.loading-text {
  position: absolute;
  z-index: 2;
  color: #000000;
  font-size: 20px;
  margin-top: 150px;
}

.navbar {
  width: 100%;
  background-color: #3d3d3d;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
  z-index: 1;
}

.menu-bars {
  margin-left: 3rem;
  font-size: 2rem;
  background: none;
  color: #f5f5f5;
}

.menu-bars:hover {
  color: #a5a5a5;
}

.nav-menu {
  background-color: #3d3d3d;
  width: 400px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  z-index: 1;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 5px 0px 5px 5px;
  list-style: none;
  height: 80px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #f5f5f5;
  color: #3d3d3d;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #3d3d3d;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.navbar-menu {
  margin-left: 16px;
  font-family: "Roboto Mono", monospace;
}

@media only screen and (min-width: 769px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 480px) {
  .nav-menu {
    width: 300px;
  }

  .nav-text {
    height: 50px;
  }

  .nav-text a {
    font-size: 14px;
    padding-left: 10px;
  }
}

.sub-header {
  text-align: left;
  font-size: 20px;
  padding-top: 25px;
}

.sort-button {
  display: inline-block;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #3d3d3d;
  color: black;
  text-align: center;
  padding: 10px;
  width: 350px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.sort-button:hover {
  font-weight: bold;
  background-color: #3d3d3d;
  color: #f5f5f5;
}

table.table.table-dark.table-striped.table-bordered.table-hover {
  table-layout: auto;
  /* width:1px;
  white-space:nowrap; */
}

.table-header,
.table-content {
  text-align: center;
}

table {
  border-collapse: collapse;
  background: hsla(0, 0%, 100%, 0.4);
  border: 1px solid black;
}

table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  border-spacing: 2px;
  border-color: grey;
  z-index: -1;
}

th {
  font-weight: 700;
  white-space: nowrap;
  background: #d1d1d1;
  color: #000000;
}

tr,
th,
td {
  border: 1px solid #000000;
  padding: 0.75rem;
}

tr.border td,
tr.border th {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-top: 1px solid black;
}

.table-button {
  display: inline-block;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #3d3d3d;
  color: black;
  text-align: center;
  padding: 10px;
  width: 100px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.table-button:hover {
  background-color: #3d3d3d;
  color: #f5f5f5;
}

.header-container {
  text-align: center;
}

.logbook-header {
  text-align: center;
}

.icon-button {
  /* float: left; */
  left: 50px;
  position: absolute;
  /* display: inline; */
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #3d3d3d;
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
  width: auto;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  margin-bottom: 20px;
}

.icon-button-additional {
  left: 200px;
  position: absolute;
  /* display: inline; */
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #3d3d3d;
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
  width: auto;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  margin-bottom: 20px;
}

.icon-button:hover {
  background-color: #3d3d3d;
  color: #f5f5f5;
}

.button-options {
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #3d3d3d;
  color: black;
  padding: 10px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  margin-bottom: 50px;
}

.register-form {
  margin-top: 50px;
}

.add-logbook-form {
  margin-top: 50px;
}

select.dropdown.form-control {
  border: 1px solid #d1d1d1;
}

select.dropdown-logbook.form-control {
  margin-bottom: 50px;
  margin-top: 50px;
  border: 2px solid #3d3d3d;
}

.button-inside-form {
  float: right;
  display: inline;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #3d3d3d;
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
  width: auto;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  margin-bottom: 20px;
}

.button-inside-form:hover {
  background-color: #3d3d3d;
  color: #f5f5f5;
}

.button-container {
}

.form-control-calendar.form-control {
  width: "80%";
}

@media only screen and (min-width: 769px) {
}

@media only screen and (max-width: 768px) {
  .logbook-header {
    margin-top: 50px;
  }
  .icon-button {
    top: 100px;
  }

  .icon-button-additional {
    top: 100px;
  }
}

@media only screen and (max-width: 480px) {
  .logbook-header {
    margin-top: 50px;
  }

  .icon-button {
    top: 100px;
    left: 25px;
  }

  .icon-button-additional {
    top: 100px;
    left: 185px;
  }
}

.sub-header {
  text-align: left;
  font-size: 20px;
  padding-top: 25px;
}

.sort-button {
  display: inline-block;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #3d3d3d;
  color: black;
  text-align: center;
  padding: 10px;
  width: 350px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.sort-button:hover {
  font-weight: bold;
  background-color: #3d3d3d;
  color: #f5f5f5;
}

table.table.table-dark.table-striped.table-bordered.table-hover {
  table-layout: auto;
  /* width:1px;
  white-space:nowrap; */
}

.table-header,
.table-content {
  text-align: center;
}

table {
  border-collapse: collapse;
  background: hsla(0, 0%, 100%, 0.4);
  border: 1px solid black;
}

table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  border-spacing: 2px;
  border-color: grey;
  z-index: -1;
}

th {
  font-weight: 700;
  white-space: nowrap;
  background: #d1d1d1;
  color: #000000;
}

tr,
th,
td {
  border: 1px solid #000000;
  padding: 0.75rem;
}

tr.border td,
tr.border th {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-top: 1px solid black;
}

.table-button {
  display: inline-block;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #3d3d3d;
  color: black;
  text-align: center;
  padding: 10px;
  width: 100px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.table-button:hover {
  background-color: #3d3d3d;
  color: #f5f5f5;
}

