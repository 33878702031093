/* font style menu */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

/* font style header */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");

/* font style content */
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@600&display=swap");

/* font style button */
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Work Sans", sans-serif;
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 50px;
  text-align: center;
  width: 100%;
}

.header {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  margin-bottom: 50px;
  text-align: center;
  width: 100%;
}

.show-all-vehicles,
.update-a-vehicle,
.register-new-vec,
.upload-vec-hist,
.show-vehicle-history,
.logbook-vehicle {
  /* display: block; */
  /* height: 200vh; */
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-left: 50px;
  margin-top: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
}

.header2 {
  margin-bottom: 25px;
  text-align: center;
}

.logo {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
}

img {
  max-width: 150px;
}

.card {
  width: 500px;
  padding: 50px;
}

.login {
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-left: 40px;
  margin-top: 100px;
  margin-right: 100px;
  margin-bottom: 150px;
  text-align: center;
}

.err-msg {
  font-weight: bold;
  color: red;
}

.success-msg {
  font-weight: bold;
  color: #014775;
  text-align: center;
  margin-top: 75px;
  margin-bottom: 75px;
}

.required {
  font-weight: bold;
  color: red;
  vertical-align: super;
}

.form-label {
  font-weight: bold;
}

.form-content {
  font-weight: normal;
}

.note {
  font-size: 14px;
}

.button-default,
button.btn.btn-primary {
  display: inline-block;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #3d3d3d;
  color: black;
  text-align: center;
  padding: 10px;
  width: 150px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
}

.button-default:hover,
button.btn.btn-primary:hover {
  font-weight: bold;
  background-color: #3d3d3d;
  color: #f5f5f5;
}

/* .dropdown-toggle.btn.btn-primary { */
.dropdown {
  width: 400px;
  border-radius: 0px;
  border: 20x solid grey;
  text-align: left;
}

.form-select {
  z-index: -1;
}

.footer {
  border-top: 1px solid #d1d1d1;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: auto;
}

.footer-left {
  /* position: absolute; */
  text-align: center;
  float: left;
  width: 20%;
}

.footer-right {
  /* position: absolute; */
  text-align: center;
  float: right;
  width: 20%;
}

:link,
a {
  color: black;
  text-decoration: none;
}

:link:hover,
a:hover {
  color: #474747;
}

:link:active,
a:active {
  color: #474747;
}

@media only screen and (min-width: 769px) {
}

@media only screen and (max-width: 768px) {
  .footer {
    border-top: 1px solid #d1d1d1;
    text-align: center;
    font-size: 12px;
  }

  .footer-left {
    text-align: left;
    width: 50%;
    padding-bottom: 20px;
  }

  .footer-right {
    text-align: right;
    width: 50%;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 480px) {
  img {
    max-width: 100px;
  }

  .card {
    width: 400px;
    padding: 20px;
  }

  .show-all-vehicles,
  .update-a-vehicle,
  .register-new-vec,
  .upload-vec-hist,
  .show-vehicle-history,
  .logbook-vehicle {
    /* display: block; */
    /* height: 200vh; */
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-left: 20px;
    margin-top: 50px;
    margin-right: 20px;
    margin-bottom: 50px;
  }

  .footer {
    border-top: 1px solid #d1d1d1;
    text-align: center;
    font-size: 12px;
  }

  .footer-left {
    text-align: center;
    width: 100%;
    padding-bottom: 20px;
  }

  .footer-right {
    text-align: center;
    width: 100%;
    padding-bottom: 20px;
  }
}
