.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #ffffff;
}

.box {
  position: relative;
  width: 300px;
  height: 400px;
  background: #000000;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.box::after {
  content: "";
  position: absolute;
  width: 500px;
  height: 500px;
  background-image: conic-gradient(
    transparent,
    transparent,
    transparent,
    #0067c7
  );
  animation: animate 4s linear infinite;
  animation-delay: -2s;
}

.box::before {
  content: "";
  position: absolute;
  width: 500px;
  height: 500px;
  background-image: conic-gradient(
    transparent,
    transparent,
    transparent,
    #f7882f
  );
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.box span {
  position: absolute;
  inset: 5px;
  border-radius: 16px;
  background: #ffffff;
  z-index: 1;
}

.loading-image {
  position: absolute;
  justify-content: center;
  align-items: center;
}

.box h2 {
  position: absolute;
  z-index: 2;
  color: #ffffff;
  font-size: 20px;
}

.loading-text {
  position: absolute;
  z-index: 2;
  color: #000000;
  font-size: 20px;
  margin-top: 150px;
}
