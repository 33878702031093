.header-container {
  text-align: center;
}

.logbook-header {
  text-align: center;
}

.icon-button {
  /* float: left; */
  left: 50px;
  position: absolute;
  /* display: inline; */
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #3d3d3d;
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
  width: auto;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  margin-bottom: 20px;
}

.icon-button-additional {
  left: 200px;
  position: absolute;
  /* display: inline; */
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #3d3d3d;
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
  width: auto;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  margin-bottom: 20px;
}

.icon-button:hover {
  background-color: #3d3d3d;
  color: #f5f5f5;
}

.button-options {
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #3d3d3d;
  color: black;
  padding: 10px;
  width: 150px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  margin-bottom: 50px;
}

.register-form {
  margin-top: 50px;
}

.add-logbook-form {
  margin-top: 50px;
}

select.dropdown.form-control {
  border: 1px solid #d1d1d1;
}

select.dropdown-logbook.form-control {
  margin-bottom: 50px;
  margin-top: 50px;
  border: 2px solid #3d3d3d;
}

.button-inside-form {
  float: right;
  display: inline;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #3d3d3d;
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
  width: auto;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  margin-bottom: 20px;
}

.button-inside-form:hover {
  background-color: #3d3d3d;
  color: #f5f5f5;
}

.button-container {
}

.form-control-calendar.form-control {
  width: "80%";
}

@media only screen and (min-width: 769px) {
}

@media only screen and (max-width: 768px) {
  .logbook-header {
    margin-top: 50px;
  }
  .icon-button {
    top: 100px;
  }

  .icon-button-additional {
    top: 100px;
  }
}

@media only screen and (max-width: 480px) {
  .logbook-header {
    margin-top: 50px;
  }

  .icon-button {
    top: 100px;
    left: 25px;
  }

  .icon-button-additional {
    top: 100px;
    left: 185px;
  }
}
