.navbar {
  width: 100%;
  background-color: #3d3d3d;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
  z-index: 1;
}

.menu-bars {
  margin-left: 3rem;
  font-size: 2rem;
  background: none;
  color: #f5f5f5;
}

.menu-bars:hover {
  color: #a5a5a5;
}

.nav-menu {
  background-color: #3d3d3d;
  width: 400px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  z-index: 1;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 5px 0px 5px 5px;
  list-style: none;
  height: 80px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #f5f5f5;
  color: #3d3d3d;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #3d3d3d;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.navbar-menu {
  margin-left: 16px;
  font-family: "Roboto Mono", monospace;
}

@media only screen and (min-width: 769px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 480px) {
  .nav-menu {
    width: 300px;
  }

  .nav-text {
    height: 50px;
  }

  .nav-text a {
    font-size: 14px;
    padding-left: 10px;
  }
}
